<template>
    <b-modal :id="modalId" centered modal-class="modal--chat-details modal-w-xl" :title="title" @hide="onCloseModal" hide-footer>
        <div class="main-table">
            <div v-if="startupsLoading" class="loading__spinner">
                <span><b-spinner class="mr-1" small></b-spinner>Loading...</span>
            </div>

            <ul v-if="chartName === 'TopInvestors'" class="select-chat-list">
                <li v-for="startup in startups" :key="startup.id" class="pr-4">
                    <div class="image-block image-block--mds">
                        <img :src="LogoHelper.getLogo(startup.logo_url)" alt="img">
                    </div>

                    <div>
                        <h5 class="heading5">
                            <a :href="'/startups/' + startup.id" target="_blank">{{startup.name}}</a>
                        </h5>

                        <b>{{startup.location}}</b>

                        <div class="d-flex">
                            <div class="mr-2">{{startup.latest_round_type}}</div>
                            <div>{{startup.latest_round_date}}</div>
                        </div>
                    </div>

                    <AddToTracker
                        :startupId="startup.id"
                        :trackersList="trackersList"
                        :trackersCount="startup.trackersCount"
                        @startupRemovedFromTracker="startupRemovedFromTracker"
                    />
                </li>
            </ul>

            <ul v-else class="select-chat-list">
                <li v-for="startup in startups" :key="startup.id" class="pr-4">
                    <div class="image-block">
                        <img :src="LogoHelper.getLogo(startup.logo_url)" alt="">
                    </div>
                    <div>
                        <h5 class="heading5">
                            <a :href="'/startups/' + startup.id" target="_blank">{{startup.name}}</a>
                        </h5>
                        <div>
                            <span class="line-clamp">{{startup.location}}</span>
                        </div>
                    </div>

                    <AddToTracker
                        :startupId="startup.id"
                        :trackersList="trackersList"
                        :trackersCount="startup.trackersCount"
                        @startupRemovedFromTracker="startupRemovedFromTracker"
                    />
                </li>
            </ul>
            <pager v-if="startups.length" :pages-count="pagesCount" :init-page="currentPage" @changePage="changePage"/>
        </div>
    </b-modal>
</template>

<script>
    import dashboard from '@/services/dashboard.service';
    import {mapActions, mapGetters} from "vuex";
    import AddToTracker from '@/components/startups/AddToTracker.vue';

    export default {
        name: 'StartupsModal',
        components: {
            AddToTracker,
            //AddToTracker: () => import("@/components/startups/AddToTracker.vue"),
            Pager: () => import("@/components/TablePager"),
        },
        props: {
            filters: Object,
        },
        data() {
            return {
                chartName: '',
                title: '',
                code: '',
                startupsLoading: false,
                startups: [],
                currentPage: 1,
                pageLength: 12,
                totalCount: 0,
            }
        },
        mounted() {
            this.fetchTrackers()
        },
        computed: {
            ...mapGetters(["trackersList"]),
            modalId() {
                return "startups-modal"
            },
            pagesCount() {
                return  Math.ceil(this.totalCount / this.pageLength)
            },
        },
        methods: {
            ...mapActions(['fetchTrackers']),
            open(chartName, title, code) {
                this.startups = []
                this.chartName = chartName;
                this.title = title;
                this.code = code;
                this.getStartups();
                this.$bvModal.show(this.modalId);
            },

            getStartups() {
                this.startupsLoading = true

                dashboard.getChartStartupsList(
                    this.chartName,
                    this.filters,
                    this.code,
                    this.currentPage,
                    this.pageLength,
                ).then(response => {
                    this.startupsLoading = false
                    this.startups = response.data.startups

                    if (this.startups.length) {
                        this.totalCount = parseInt(response.data.total_count)
                        this.currentPage = parseInt(response.data.current_page)
                    } else {
                        this.$bvModal.hide(this.modalId)
                    }
                })
            },

            changePage(page) {
                this.currentPage = page
                this.getStartups()
            },

            onCloseModal() {
                this.startups = []
                this.currentPage = 1
                this.totalCount = 0
            },

            startupRemovedFromTracker() {
                this.getStartups()
                this.$emit('startupRemovedFromTracker')
            }
        }
    }
</script>
